'use strict';

Gri.module({
  name: 'button-gamma',
  ieVersion: null,
  $el: $('.button-gamma'),
  container: '.button-gamma',
  fn: function () {
    
  }
});
